import { AppActions, AuthActions } from '@cloverxuesongzhou/types';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

interface ContactProps {
  dispatch: (arg0: AuthActions | AppActions) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Contact: React.FC<ContactProps> = ({ dispatch }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)',
  });
  return (
    <>
      {isDesktopOrLaptop ? (
        <div className="row">
          <div className="col-2"></div>
          <div className="col-8 px-5">
            <p>Clover Xuesong Zhou</p>
            <p>Email: clover.x.zhou@gmail.com</p>
            <p>Address: 709 Locust St. #8, Pasadena, CA 91101</p>
            <p>
              Instagram:{' '}
              <a href="http://www.instagram.com/zhouxuesong16" target="_blank" rel="noopener noreferrer">
                zhouxuesong16
              </a>
            </p>
          </div>
          <div className="col-2"></div>
        </div>
      ) : (
        <div className="row">
          <div className="col-1"></div>
          <div className="col-10 text-center pt-5">
            <p>Clover Xuesong Zhou</p>
            <p>clover.x.zhou@gmail.com</p>
            <p>709 Locust St. #8, Pasadena, CA 91101</p>
            <p>
              Instagram:{' '}
              <a href="http://www.instagram.com/zhouxuesong16" target="_blank" rel="noopener noreferrer">
                zhouxuesong16
              </a>
            </p>
          </div>
          <div className="col-1"></div>
        </div>
      )}
    </>
  );
};
