import { AppActions, AuthActions } from '@cloverxuesongzhou/types';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import clover from './Clover-Xuesong-Zhou.jpg';

interface AboutProps {
  dispatch: (arg0: AuthActions | AppActions) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const About: React.FC<AboutProps> = ({ dispatch }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)',
  });

  return (
    <>
      {isDesktopOrLaptop ? (
        <div className="row">
          <div className="col-2"></div>
          <div className="col-8 px-5 mb-5">
            <div className="py-4" style={{ lineHeight: '1.2rem', textAlign: 'justify' }}>
              <img
                src={clover}
                alt="Clover Xuesong Zhou"
                style={{ maxWidth: '50%', float: 'left' }}
                className="pr-5 pt-1 pb-3 mr-2 font-normal"
              />
              <p className="font-normal">
                Clover Xuesong Zhou is a Chinese writer, artist, and theologian. She currently lives and works in Los
                Angeles, California.{' '}
              </p>

              <p className="font-normal">
                Zhou has a M.A. in Theology from Fuller Theological Seminary and an M.A. in Fine Art from Dalian
                Polytechnic University. She worked in the contemporary art field as a journalist, editor, and art critic
                from 2010 to 2017 in Beijing, and from 2017 to the present in Los Angeles. She has written for many
                publications, including:
                <span className="font-italic"> Art Forum China</span>,
                <span className="font-italic"> Art Newspaper China</span>,
                <span className="font-italic"> The Art World</span>,<span className="font-italic"> RanDian</span>,
                <span className="font-italic"> Vision</span>,<span className="font-italic"> Oriental Art-Master</span>,
                <span className="font-italic"> Artron Art</span>.{' '}
              </p>

              <p className="font-normal">
                In 2020, Clover Xuesong Zhou co-curated <span className="font-italic">A Composite Leviathan</span> with
                James Elain and Cara Lewis at <span className="font-italic">Bridge Projects</span>, Los Angeles, and she
                curated a series of educational programs for the exhibition bridging China and the United States as well
                as the art world and faith traditions.
              </p>
              <p className="font-normal">
                Clover is also a visual artist focused on painting, installations, and video art. Christian faith is the
                center and muse of her work: to pursue, reveal, present, and share the truth and love of God through the
                language of art.
              </p>
              <p className="font-normal">
                Clover is married to John Camden, a biblical scholar, author, musician, web developer, and digital
                artist. They are expecting their first baby in April, 2021.
              </p>
            </div>
          </div>
          <div className="col-2"></div>
        </div>
      ) : (
        <div className="row">
          <div className="col-1"></div>
          <div className="col-10">
            <div className="mb-5 pb-5" style={{ lineHeight: '1.2rem', textAlign: 'justify' }}>
              <img src={clover} alt="Clover Xuesong Zhou" className="pt-1 pb-3 font-normal w-100" />
              <p className="font-normal">
                Clover Xuesong Zhou is a Chinese writer, artist, and theologian. She currently lives and works in Los
                Angeles, California.{' '}
              </p>

              <p className="font-normal">
                Zhou has a M.A. in Theology from Fuller Theological Seminary and an M.A. in Fine Art from Dalian
                Polytechnic University. She worked in the contemporary art field as a journalist, editor, and art critic
                from 2010 to 2017 in Beijing, and from 2017 to the present in Los Angeles. She has written for many
                publications, including:
                <span className="font-italic"> Art Forum China</span>,
                <span className="font-italic"> Art Newspaper China</span>,
                <span className="font-italic"> The Art World</span>,<span className="font-italic"> RanDian</span>,
                <span className="font-italic"> Vision</span>,<span className="font-italic"> Oriental Art-Master</span>,
                <span className="font-italic"> Artron Art</span>.{' '}
              </p>

              <p className="font-normal">
                In 2020, Clover Xuesong Zhou co-curated <span className="font-italic">A Composite Leviathan</span> with
                James Elain and Cara Lewis at <span className="font-italic">Bridge Projects</span>, Los Angeles, and she
                curated a series of educational programs for the exhibition bridging China and the United States as well
                as the art world and faith traditions.
              </p>
              <p className="font-normal">
                Clover is also a visual artist focused on painting, installations, and video art. Christian faith is the
                center and muse of her work: to pursue, reveal, present, and share the truth and love of God through the
                language of art.
              </p>
              <p className="font-normal">
                Clover is married to John Camden, a biblical scholar, author, musician, web developer, and digital
                artist. They are expecting their first baby in April, 2021.
              </p>
            </div>
          </div>
          <div className="col-1"></div>
        </div>
      )}
    </>
  );
};
