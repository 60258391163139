// export type Page = 'login' | 'register' | 'profile' | 'writing' | 'art' | 'log' | 'about' | 'contact';

export type Page = string;

export type Modal = '' | 'login' | 'register';

export interface AppStateInterface {
  page: Page;
  modal: Modal;
  onSetQsPage: (newValue: string) => void;
}

export enum AppActionTypes {
  changePage = 'changePage',
  setModal = 'setModal',
}

export type AppActions =
  | {
      type: AppActionTypes.changePage;
      payload: Page;
    }
  | { type: AppActionTypes.setModal; payload: Modal };
