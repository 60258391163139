// These are all the FA icons which were used in the original Djinndex app.
// Figured I'll proly be usin' em again.

import { library } from '@fortawesome/fontawesome-svg-core';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import {
  faArrowCircleLeft,
  faArrowCircleRight,
  faBible,
  faBook,
  faEdit,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFilePdf,
  faFlag,
  faInfinity,
  faLock,
  faLockOpen,
  faMinusCircle,
  faPlusCircle,
  faPlusSquare,
  faSearch,
  faSearchMinus,
  faSearchPlus,
  faSignOutAlt,
  faStream,
  faTimesCircle,
  faTools,
  faTrashAlt,
  faUser,
  faUserEdit,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faArrowCircleLeft,
  faArrowCircleRight,
  faBible,
  faBook,
  faEdit,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFilePdf,
  faFlag,
  faGoogle,
  faInfinity,
  faLock,
  faLockOpen,
  faMinusCircle,
  faPlusCircle,
  faPlusSquare,
  faSearch,
  faSearchMinus,
  faSearchPlus,
  faSignOutAlt,
  faStream,
  faTimesCircle,
  faTools,
  faTrashAlt,
  faUser,
  faUserEdit,
  faUserPlus,
);
