import { AppActionTypes, AuthStateInterface, IDispatch } from '@cloverxuesongzhou/types';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { ColorChunk } from './ColorChunk/ColorChunk';
import { ColorChunkSmall } from './ColorChunk/ColorChunkSmall';
import { NavStateLink } from './NavLink/NavStateLink';

// import { NavStateLinkIcon } from './NavLink/NavStateLinkIcon';
// import { UserDropdown } from './UserDropdown/UserDropdown';

interface NavBarProps {
  dispatch: IDispatch;
  page: string;
  user: AuthStateInterface['user'];
  onSetQsPage: (newValue: string) => void;
  isDesktopOrLaptop: boolean;
}

export const NavBar: React.FC<NavBarProps> = ({ dispatch, page, onSetQsPage, isDesktopOrLaptop }) => {
  // const authdLinks = (
  //   <>
  //     <NavStateLink
  //       actions={[{ type: AppActionTypes.changePage, payload: 'profile' }]}
  //       dispatch={dispatch}
  //       page={page}
  //       px={2}
  //       size="1rem"
  //       text={`${user?.givenName} ${user?.familyName}`}
  //       onSetQsPage={onSetQsPage}
  //     />

  //     <NavStateLinkIcon
  //       actions={[
  //         { type: AuthActionTypes.logOut, payload: {} },
  //         // { type: AppActionTypes.changePage, payload: 'writing' },
  //       ]}
  //       dispatch={dispatch}
  //       page={page}
  //       px={2}
  //       size="1rem"
  //       text={<FontAwesomeIcon className="mr-2" icon="sign-out-alt" />}
  //       onSetQsPage={onSetQsPage}
  //     />
  //   </>
  // );

  // const unAuthdLinks = <UserDropdown dispatch={dispatch} page={page} />;

  const links = (
    <>
      <NavStateLink
        actions={[{ type: AppActionTypes.changePage, payload: 'writing' }]}
        dispatch={dispatch}
        page={page}
        px={isDesktopOrLaptop ? 3 : 1}
        size={isDesktopOrLaptop ? '1.2rem' : '1rem'}
        text="WRITING"
        onSetQsPage={onSetQsPage}
      />

      <NavStateLink
        actions={[{ type: AppActionTypes.changePage, payload: 'art' }]}
        dispatch={dispatch}
        page={page}
        px={isDesktopOrLaptop ? 3 : 1}
        size={isDesktopOrLaptop ? '1.2rem' : '1rem'}
        text="ART"
        onSetQsPage={onSetQsPage}
      />
      <NavStateLink
        actions={[{ type: AppActionTypes.changePage, payload: 'log' }]}
        dispatch={dispatch}
        page={page}
        px={isDesktopOrLaptop ? 3 : 1}
        size={isDesktopOrLaptop ? '1.2rem' : '1rem'}
        text="LOG"
        onSetQsPage={onSetQsPage}
      />

      <NavStateLink
        actions={[{ type: AppActionTypes.changePage, payload: 'about' }]}
        dispatch={dispatch}
        page={page}
        px={isDesktopOrLaptop ? 3 : 1}
        size={isDesktopOrLaptop ? '1.2rem' : '1rem'}
        text="ABOUT"
        onSetQsPage={onSetQsPage}
      />

      <NavStateLink
        actions={[{ type: AppActionTypes.changePage, payload: 'contact' }]}
        dispatch={dispatch}
        page={page}
        px={isDesktopOrLaptop ? 3 : 1}
        size={isDesktopOrLaptop ? '1.2rem' : '1rem'}
        text="CONTACT"
        onSetQsPage={onSetQsPage}
      />
    </>
  );

  const title = 'CLOVER XUESONG ZHOU';

  return (
    <nav>
      <div
        className={`${
          isDesktopOrLaptop ? 'mt-5' : 'mt-2'
        } mb-0 pt-3 d-flex flex-row bg-white justify-content-between align-items-center`}
      >
        {isDesktopOrLaptop ? (
          <h1 className="font-logo text-dark mb-0">
            {title.split('').map((text, index) => (
              <ColorChunk key={`ColorChunk${index}`} text={text} />
            ))}
          </h1>
        ) : (
          <h3 className="font-logo text-dark mb-0 mt-5 text-center w-100">
            {title.split('').map((text, index) => (
              <ColorChunk key={`ColorChunk${index}`} text={text} />
            ))}
          </h3>
        )}

        {/* TEMPORARILY DISABLED USER FEATURES DUE TO CORS ISSUES */}
        {/* <div className="navbar-nav d-flex flex-row text-secondary">
          {user?.self !== 'guest' ? authdLinks : unAuthdLinks}
        </div> */}
      </div>

      <div
        className={`${
          isDesktopOrLaptop ? 'justify-content-left' : 'justify-content-center'
        } pb-3 pt-2 d-flex flex-row bg-white`}
      >
        <ColorChunkSmall text={'Writer'} page={page} />
        <span className="text-secondary">/</span>
        <ColorChunkSmall text={'Artist'} page={page} />
        <span className="text-secondary">/</span>
        <ColorChunkSmall text={'Theologian'} page={page} />
        {/* {subtitle.split(',').map(text => (
          <SubtitleLetter text={text} />
        ))} */}
        {/* <div className="text-secondary h5">Writer/Artist/Theologian</div> */}
      </div>
      <div className={`${isDesktopOrLaptop ? 'pb-3' : 'pb-1'} d-flex flex-row-reverse bg-white`}>
        <div
          className={`navbar-nav d-flex justify-content-between flex-row text-secondary ${!isDesktopOrLaptop &&
            'w-100'}`}
        >
          {links}
        </div>
      </div>
    </nav>
  );
};
