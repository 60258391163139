import React, { useEffect, useState } from 'react';

interface ColorChunkSmallProps {
  text: string;
  page: string;
}

const colorBySelected = (text: string, page: string): string => {
  if (text.toLowerCase() === page.toLowerCase()) {
    return '#474747';
  } else {
    return '#7d7d7d';
  }
};

const randomColor = (): string => Math.floor(Math.random() * 16777215).toString(16);

export const ColorChunkSmall: React.FC<ColorChunkSmallProps> = ({ text, page }) => {
  const [color, setColor] = useState(colorBySelected(text, page));
  const [textShadowSize, setTextShadowSize] = useState('0');
  const [textShadowColor, setTextShadowColor] = useState('#ffffff');

  useEffect(() => {
    setColor(colorBySelected(text, page));
  }, [text, page]);

  return (
    <span
      onMouseEnter={(): void => {
        const newColor = `#${randomColor()}`;
        setColor(newColor);
        setTextShadowColor(newColor);
        setTextShadowSize('1');
      }}
      onMouseLeave={(): void => {
        setColor(colorBySelected(text, page));
        setTextShadowColor('#ffffff');
        setTextShadowSize('0');
      }}
      style={{
        color: color,
        textShadow: `0px 0px ${textShadowSize}px ${textShadowColor}`,
        cursor: `${text === 'Writer' || text === 'Artist' || text === 'Theologian' ? 'default' : 'cursor'}`,
      }}
    >
      {text}
    </span>
  );
};
