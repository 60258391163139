import React from 'react';

interface ArtItemMobileProps {
  date: string;
  description: string;
  img: string;
  title: string;
}

export const ArtItemMobile: React.FC<ArtItemMobileProps> = ({ date, description, img, title }) => {
  return (
    <div className="row mb-0 mt-0">
      <div className="col-1"></div>
      <div className="col-10 text-center">
        <img src={img} alt="" style={{ maxWidth: '100%' }} className="shadow" />
      </div>
      <div className="d-flex flex-column justify-content-end w-100 align-items-center py-3">
        <div className="font-italic">{title}</div>
        <div>{description}</div>
        <div>{date}</div>
      </div>
      <div className="col-1"></div>
    </div>
  );
};
