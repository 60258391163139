import '@cloverxuesongzhou/lib/src/themes/index.scss';

import './utils/faLib';

import { NavBar } from '@cloverxuesongzhou/lib';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { DispatchWrappedStateRouter } from './components/hoc/DispatchWrappedStateRouter';
import { NavBarPropProvider } from './components/hoc/NavBarPropProvider';
import { ModalProvider } from './components/ModalProvider';
import { AppState } from './context/app/AppState';
import { AuthState } from './context/auth/AuthState';
import { ProjectsState } from './context/projects/ProjectsState';

export const App: React.FC = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)',
  });
  // const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' });
  // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  // const isTabletOrMobileDevice = useMediaQuery({
  //   query: '(max-device-width: 1224px)',
  // });
  // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

  return (
    <div className="App">
      <AuthState>
        <AppState>
          <ProjectsState>
            <ModalProvider />
            <div className={`${isDesktopOrLaptop ? 'row mt-5 pt-4' : 'row'}`}>
              <div className={`${isDesktopOrLaptop ? 'col-2' : 'col-1'}`}></div>
              <div className={`${isDesktopOrLaptop ? 'col-8' : 'col-10 px-0'}`}>
                <NavBarPropProvider>
                  <NavBar
                    isDesktopOrLaptop={isDesktopOrLaptop}
                    dispatch={(): void => {
                      console.log('dispatch was not replaced by NavBarPropProvider');
                    }}
                    user={{ self: 'init', email: 'init', givenName: 'init', familyName: 'init', _v: 0 }}
                    page="placeholder"
                    onSetQsPage={(newValue: string): void =>
                      console.log(`${newValue}: onSetQsPage was not replaced by NavBarPropProvider`)
                    }
                  />
                </NavBarPropProvider>
                <div className={`${isDesktopOrLaptop ? 'col-2' : 'col-1'}`}></div>
              </div>
            </div>
            <Router>
              <Switch>
                <Route exact path="/" component={DispatchWrappedStateRouter} />
              </Switch>
            </Router>
          </ProjectsState>
        </AppState>
      </AuthState>
    </div>
  );
};
