import React from 'react';
import { useMediaQuery } from 'react-responsive';

export interface LogItemProps {
  title: string | JSX.Element;
  text: JSX.Element;
  imgURL: string;
  imgAlt: string;
  width: string;
  float: string;
  date: string;
}

export const LogItem: React.FC<LogItemProps> = ({ title, text, imgURL, imgAlt, width, float, date }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)',
  });
  return (
    <>
      {' '}
      {isDesktopOrLaptop ? (
        <div className="pb-5 mb-5 mt-4">
          <div className="text-justify">
            <img src={imgURL} alt={imgAlt} className={`pr-5 pt-1 pb-4 font-normal w-${width} float-${float}`} />

            <h2 className="font-header">{title}</h2>
            <h4 className="font-header text-right">{date}</h4>
            <p className="">{text}</p>
          </div>
        </div>
      ) : (
        <div className="pb-5 mb-5 mt-2">
          <div className="text-justify">
            <div className="d-flex justify-content-between align-items-end">
              <h3 className="font-header ">{title}</h3>
              <h4 className="font-header text-right">{date}</h4>
            </div>
            <img src={imgURL} alt={imgAlt} className={` pt-1 pb-2 font-normal w-100`} />
            <p className="">{text}</p>
          </div>
        </div>
      )}
    </>
  );
};
