import React from 'react';

interface ArtItemProps {
  date: string;
  description: string;
  img: string;
  title: string;
}

export const ArtItem: React.FC<ArtItemProps> = ({ date, description, img, title }) => {
  return (
    <div className="row mb-2 mt-5">
      <div className="col-2"></div>
      <div className="col-8 text-center">
        <div
          style={{
            height: '28rem',
            width: '100%',
            overflow: 'hidden',
            backgroundImage: `url('${img}')`,
            backgroundPosition: '50% 50%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
          className="shadow"
        ></div>

        {/* <img src={img} alt="" style={{ maxWidth: '100%' }} className="shadow" /> */}
      </div>
      <div className="col-2 pt-5 pl-3">
        <div className="d-flex flex-column justify-content-end h-75">
          <div className="font-italic">{title}</div>
          <div>(断空？空断)</div>
          <div>{description}</div>
          <div>{date}</div>
        </div>
      </div>
    </div>
  );
};
