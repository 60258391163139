import React from 'react';

export interface LogItemProps {
  title: string | JSX.Element;
  text: JSX.Element;
  imgURL: string;
  imgAlt: string;
  width: string;
  float: string;
  date: string;
}

export const LogItem: React.FC<LogItemProps> = ({ title, text, imgURL, imgAlt, width, float, date }) => {
  return (
    <>
      <div className="pb-5 mb-5 mt-4">
        <div style={{ lineHeight: '1.2rem', textAlign: 'justify' }}>
          <img
            src={imgURL}
            alt={imgAlt}
            className={`pr-5 pt-1 pb-4 font-normal float-${float}`}
            style={{ width: `${width}%` }}
          />

          <h2 className="font-header">{title}</h2>
          <h4 className="font-header text-right">{date}</h4>
          <p className="font-normal">{text}</p>
        </div>
      </div>
    </>
  );
};
