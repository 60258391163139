import React, { useState } from 'react';

interface ArtMenuMobileProps {
  activeSeries: string;
  setActiveSeries: React.Dispatch<React.SetStateAction<string>>;
}

export const ArtMenuMobile: React.FC<ArtMenuMobileProps> = ({ activeSeries, setActiveSeries }) => {
  console.log(activeSeries);
  const [hovered, setHovered] = useState(false);
  return (
    <div className="d-flex flex-column text-center pt-3 pb-5">
      <div
        style={{
          padding: '.4rem',
          backgroundColor: activeSeries === 'infinity' ? '#e3e3e3' : 'white',
          cursor: hovered ? 'pointer' : 'default',
        }}
        onClick={(): void => setActiveSeries('infinity')}
        onMouseEnter={(): void => setHovered(true)}
        onMouseLeave={(): void => setHovered(false)}
      >
        Infinity Series
      </div>
      <div
        style={{
          padding: '.4rem',
          backgroundColor: activeSeries === 'stop' ? '#e3e3e3' : 'white',
          cursor: hovered ? 'pointer' : 'default',
        }}
        onClick={(): void => setActiveSeries('stop')}
        onMouseEnter={(): void => setHovered(true)}
        onMouseLeave={(): void => setHovered(false)}
      >
        Stop Emptiness? Stop in Vain
      </div>
    </div>
  );
};
