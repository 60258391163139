import './StopEmptiness.css';

import React, { useState } from 'react';

import { ArtItem } from './ArtItemVanity';
import stop0 from './img/stop-emptiness/0.jpg';
import stop1 from './img/stop-emptiness/1.jpg';
import stop2 from './img/stop-emptiness/2.jpg';
import stop3 from './img/stop-emptiness/3.jpg';

const paintByNumber = (number: number): string => {
  switch (number) {
    case 0:
      return stop0;
    case 1:
      return stop1;
    case 2:
      return stop2;
    case 3:
      return stop3;
    default:
      return stop0;
  }
};

const numberSeries = Array.from(Array(4).keys());

export const StopEmptiness: React.FC = () => {
  const [selected, setSelected] = useState('0');
  const [img, setImg] = useState(stop0);

  return (
    <div className="artpage">
      <div>
        <ArtItem
          date="2010"
          description="cotton threads, spools of thread, spot light"
          img={img}
          title={`Stop Emptiness? Stop in Vain`}
        />
      </div>
      <div className="d-flex flex-row justify-content-center">
        {numberSeries.map(
          (number): JSX.Element => {
            return (
              <div
                style={{
                  height: '7rem',
                  width: '7rem',
                }}
                key={`number${number}`}
                className={`${parseInt(selected) === number && 'artFrame'}`}
              >
                <div
                  className={`w-100 h-100 stopEmptiness${number} stopEmptiness`}
                  onMouseEnter={(): void => {
                    setImg(paintByNumber(number));
                    setSelected('' + number);
                  }}
                ></div>
              </div>
            );
          },
        )}
      </div>
      <div className="p-5 mx-5 text-justify">
        <h2>Artist&apos;s Statement</h2>
        <p>
          Things are limited, while emptiness is unlimited. Can any one establish an end to emptiness? Or are such
          apparent ends all established in vain? The installation, &quot;Stop Emptiness? Stop in Vain&quot; (断空？空断)
          presents these philosophical questions.
        </p>

        <p>
          The exhibition space is the broadest emptiness, as it were, in the artwork. The emptiness is divided by
          numerous threads which attract the attention of the audience. The pull of gravity on the soft threads has made
          them solid, vertical entities. The various lengths of the threads create an impression that their respective
          spool were cut while falling in mid-air. However, the spools are still connected to their threads via the
          shadows of the threads on the ground.
        </p>

        <p>
          The Chinese character used in the title, &quot;断” literally means &quot;stop” or &quot;cut”, but it also
          figuratively means &quot;judge” or &quot;decide.” It is a word which can be used to reveal the state of a
          person&apos;s mind. Thus, the title can also be translated as, &quot;Judge emptiness? Judge in vain.&quot;
        </p>
        <div className="text-center text-secondary mt-5">Copyright © 2020 Clover Xuesong Zhou</div>
      </div>
    </div>
  );
};
