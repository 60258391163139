import { AppActions, AuthActions } from '@cloverxuesongzhou/types';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { LogItem, LogItemProps } from './LogItem';

interface LogProps {
  dispatch: (arg0: AuthActions | AppActions) => void;
  logs: LogItemProps[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Log: React.FC<LogProps> = ({ dispatch, logs }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)',
  });

  return (
    <>
      {isDesktopOrLaptop ? (
        <div className="row">
          <div className="col-2"></div>
          <div className="col-8 px-5">
            {logs.map((log, index) => (
              <LogItem
                key={`log${index}`}
                title={log.title}
                date={log.date}
                text={log.text}
                imgURL={log.imgURL}
                imgAlt={log.imgAlt}
                width={log.width}
                float={log.float}
              />
            ))}
          </div>
          <div className="col-2"></div>
        </div>
      ) : (
        <div className="row">
          <div className="col-1"></div>
          <div className="col-10">
            {logs.map((log, index) => (
              <LogItem
                key={`log${index}`}
                title={log.title}
                date={log.date}
                text={log.text}
                imgURL={log.imgURL}
                imgAlt={log.imgAlt}
                width={log.width}
                float={log.float}
              />
            ))}
          </div>
          <div className="col-1"></div>
        </div>
      )}
    </>
  );
};
