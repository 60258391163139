import React from 'react';

interface ArtItemVanityMobileProps {
  date: string;
  description: string;
  img: string;
  title: string;
}

export const ArtItemVanityMobile: React.FC<ArtItemVanityMobileProps> = ({ date, description, img, title }) => {
  return (
    <div className="text-center d-flex flex-column align-items-center">
      <div
        style={{
          height: '78vw',
          width: '78vw',
          overflow: 'hidden',
          backgroundImage: `url('${img}')`,
          backgroundPosition: '50% 50%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
        className="shadow"
      ></div>
      <div className="d-flex flex-column justify-content-end mt-3">
        <div className="font-italic">{title}</div>
        <div>(断空？空断)</div>
        <div>{description}</div>
        <div>{date}</div>
      </div>
      {/* <img src={img} alt="" style={{ maxWidth: '100%' }} className="shadow" /> */}
    </div>
  );
};
