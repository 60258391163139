import { AppActions, AuthActions } from '@cloverxuesongzhou/types';
import React from 'react';

interface ProfileProps {
  dispatch: (arg0: AuthActions | AppActions) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Profile: React.FC<ProfileProps> = ({ dispatch }) => {
  return <div>User features coming soon!</div>;
};
