import { AppActions, AuthActions } from '@cloverxuesongzhou/types';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { WritingEntry } from './WritingEntry/WritingEntry';

interface WritingProps {
  dispatch: (arg0: AuthActions | AppActions) => void;
}

const writingsArray = [
  {
    abstract:
      'The metaphor of “light in the cracks” provides an interpretive framework to unite the sundry constituents of the exhibition. The illuminatation inauthentic brokenness and the redemption of brokenness through authenticity are two sides of the same coin, a currency of healing and love.',
    date: '(forthcoming)',
    imgClass: 'compositeLeviathan',
    publication: 'A Composite Leviathan',
    title: 'Light Through the Cracks',
    url: '/?page=light-through-the-cracks',
  },
  {
    abstract:
      'Contemporary Chinese Christian artists are the unique product of three cultural heritages, each with a complicated relationship and history with the others, namely, Chinese culture, Christianity, and contemporary art.',
    date: 'December, 2019',
    imgClass: 'cqContemporaryChineseArtAndChristianity',
    publication: 'China Quarterly',
    title: 'Contemporary Chinese Art and Christianity',
    url: 'https://www.chinasource.org/resource-library/articles/contemporary-chinese-art-and-christianity/',
  },
  {
    abstract:
      '《圣经》中与上帝抗衡的水怪“利维坦”经由霍布斯的政治哲学理论为人熟知：个人出让“自然权力”给国家，这一社会契约的缔结让国家成为拥有极大权能的怪兽。该隐喻在今天的全球政治图景下似乎依然具有普遍意义。',
    date: 'December, 2019',
    imgClass: 'luhringAugusting',
    publication: 'Artforum 展评',
    title: '拼拼凑凑的利维坦',
    url: 'https://www.artforum.com.cn/picks/12525',
  },
  {
    abstract:
      '有着流年寓意的十二生肖与难民的漂流者形象互相交织，作品关注的对象也从难民群体的具体命运上升到当下人类的共同命运：在急速发展的今日世界中，每一个人都面临被流放、被错置、或被疏离的危险，每个人都是潜在的漂流者。而这一过程在流年中一再循环，无人能侥幸逃脱。',
    date: 'December, 2018',
    imgClass: 'marcianoArtFoundation',
    publication: 'Artforum 展评',
    title: '艾未未',
    url: 'https://www.artforum.com.cn/picks/11847',
  },
  {
    abstract:
      '从死猪到死鸡，下一个系统崩坏点是什么？是否依旧要以个人的妥协解决问题？如果说老王和王招娣之间的道德争议造就了电影高潮的张力，那么电影结尾则将这一张力收缩到一种隐性的政治批判，也是这部超现实主义风格的现实题材电影最后一个黑色幽默的注脚。',
    date: 'May, 2018',
    imgClass: 'theDeadPig',
    publication: 'Artforum 专栏',
    title: '系统崩坏点',
    url: 'https://www.artforum.com.cn/film/11380',
  },
  {
    abstract:
      '展览召集人和回应人共同在工作中深入各自对“自我批评”的理解，形成了诸多彼此平行的想法，而非谁成为谁的注解。',
    date: 'June, 2017',
    imgClass: 'liuDingLuYinghuaLuoXioming',
    publication: 'Artforum 所见所闻',
    title: '批评的姿态与根基',
    url: 'https://www.artforum.com.cn/diary/10670',
  },
  {
    abstract:
      '隐忍的叙述让刘鼎的自我批评也成为了某种艺术家内在的隐逸行动，因为除了历史公共性的问题之外，其自我反思的具体内容着实令观者实难捕捉。然而我们在展览中至少可以看到由反思的行动所带出的情感的形状，这在韩东的诗作中有清晰的描绘......',
    date: 'May, 2017',
    imgClass: 'magicianSpace',
    publication: 'Artforum 展评',
    title: '刘鼎与韩东：就此别过',
    url: 'https://www.artforum.com.cn/picks/10586',
  },
  {
    abstract:
      '秦晋和陈丹笛子的作品都有着细腻、敏感、内敛的特质，且都强调叙事性，只是面对相似的问题时，她们的态度不尽相同——秦晋作品包含着相对明确的政治指向，个人情感的投入也更深；陈丹笛子的作品则相对冷静疏离，我们难于从作品中看到艺术家本人的态度。',
    date: 'January, 2017',
    imgClass: 'qinJin',
    publication: 'Artforum 展评',
    title: '浮生手记',
    url: 'https://www.artforum.com.cn/picks/10354',
  },
  {
    abstract:
      '将冯君蓝的摄影作品放入当代艺术的语境似乎显得有点格格不入，它们看起来如此温暖以至于缺乏批判精神，太过完整以至于缺乏解构的方法，以耶稣基督为中心的观念也让他的创作与当代去中心化的流行观念格格不入......',
    date: 'November, 2016',
    imgClass: 'feungJunlianDavid',
    publication: '',
    title: '冯君蓝：凝视与行动',
    url:
      'https://mp.weixin.qq.com/s?__biz=MjM5OTM2NjU2NA==&mid=2651034581&idx=1&sn=e4cc8e7af41b007991f3383cbb088ab7&chksm=bccba91e8bbc20087e1bebccdf7c9a93a5711b45736c668d21bcfe7fa8187aa11e19166d0ff7&mpshare=1&scene=1&srcid=0819A5dMebZXH5eCosVcGtlU&sharer_sharetime=1597782620737&sharer_shareid=6aacb9573bab467dfd02e1f179e388a7&exportkey=AfFsmocu3OZv1x%2BLu2KNr6Y%3D&pass_ticket=tItLG0vo4ZGcS2Bmzh3c12DOiVSvWZJ4cVPkCH0IzM9AY2K0wTJnLkznt6q3gWMD&wx_header=0#rd',
  },
  {
    abstract:
      '一只手持着摄像机，一只手在镜头前指引、行动和表演，一边拍摄一边喃喃低语。劳拉·普罗沃斯特（Laure Prouvost）的散文式电影制造出一种极具个人化的、坦诚率真的叙事情景。',
    date: 'August, 2016',
    imgClass: 'laureProuvostGrandmasDream',
    publication: '',
    title: '关于劳拉·普罗沃斯特中国首展的15段评论',
    url:
      'https://mp.weixin.qq.com/s?__biz=MzA3OTI4MDYzNQ==&mid=2654444591&idx=1&sn=e6b7924eb55779e8a4bdd44c147f3cf8&chksm=8476dcbbb30155ad114da7eac5f4ce5881e773b28e0e6abcaf99eaff8007825a481df98ef81e&mpshare=1&scene=1&srcid=08065jWvNC0JD4PQJiIiukm4&sharer_sharetime=1597782870764&sharer_shareid=6aacb9573bab467dfd02e1f179e388a7&exportkey=AWdfjfVWT7opRYC1RnAthEI%3D&pass_ticket=tItLG0vo4ZGcS2Bmzh3c12DOiVSvWZJ4cVPkCH0IzM9AY2K0wTJnLkznt6q3gWMD&wx_header=0#rd',
  },
  {
    abstract:
      '种种冲突令他制造的关于“人脸”、“启示”的导引成为了一个被架空的理想观念。当艺术家设定的观念在创作中被自我消解的时候，看似创造了左右逢源的机会，实则令作品观念本身的力量大大消减。',
    date: 'August, 2016',
    imgClass: 'wangSishun',
    publication: 'RanDian',
    title: '石头之于艺术的普遍启示',
    url: 'https://www.artforum.com.cn/diary/10670',
  },
  {
    abstract:
      '这是⼀个故乡被渐渐模糊了的时代。全球化的⽇益加剧和⽹络时代的兴起，使得艺术家如同这 个虚⽆世界中的漂泊者⼀样，越发去除了原⽣⽂化浸染的表征。简单地说，我们难于像从前那 样从艺术作品的样貌中轻松读取艺术家的个⼈⾝份密码。  ',
    date: 'July, 2016',
    imgClass: 'liRanSameOldCrowd',
    publication: '',
    title: '李然，身为异乡客',
    url:
      'http://www.li-ran.org/wp-content/uploads/2016/07/%E6%9D%8E%E7%84%B6%EF%BC%8C%E8%BA%AB%E4%B8%BA%E5%BC%82%E4%B9%A1%E5%AE%A2-%E2%80%94%E2%80%94%E5%91%A8%E9%9B%AA%E6%9D%BE.pdf',
  },
  {
    abstract:
      'Tabula Rasa画廊的群展“操行零分”在题目上呼应了法国导演让·维果（Jean Vigo）1932年的同名电影，这种呼应既是一种“致敬”，又在某种程度上以电影中那群聪明、爱搞怪的小学生占领学校的反叛行动为蓝本，提出了针对当下艺术体制的质询。',
    date: 'May, 2016',
    imgClass: 'tabulaRasa',
    publication: 'Artforum 展评',
    title: '操行零分',
    url: 'https://www.artforum.com.cn/picks/9496',
  },
  {
    abstract:
      '“起初，神创造天地。地是空虚混沌，渊面黑暗。”安东尼·葛姆雷（Antony Gormley）在为他在北京常青画廊的最新个展“屯蒙”（Host）进行导览时，站在他的水墨作品《地球表面之暗》（Darkness On the Face of the Earth，2016）前，说出了这两句圣经经文。',
    date: 'April, 2016',
    imgClass: 'antonyGormlyPost',
    publication: 'Artforum 观点',
    title: '微缩的神学史',
    url: 'https://www.artforum.com.cn/slant/9373',
  },

  {
    abstract:
      '木条、木板，长的、短的；椰子、土豆，大的，小的；树根、面团，硬的、软的……画廊之前的展览留下的以及工作人员从四处随机捡来的物品，在刘月的手中被拼接在一起，漫无目的的连成一片，却又好像是经过精密计算而成的空间抽象图景。',
    date: 'April, 2016',
    imgClass: 'liuYue',
    publication: 'Artforum 展评',
    title: '刘月：堆',
    url: 'https://www.artforum.com.cn/picks/9281',
  },
  {
    abstract:
      '严肃艺术评论在日益资本化的艺术市场中，在互联网对媒体带来的激烈冲击之下，也不复昔日的权威。而在中国，艺术评论家与策展人、销售者的专业分工从未真正建立。',
    date: 'October, 2015',
    imgClass: 'iiac',
    publication: 'The Art Newspaper China',
    title: '第二届IAAC颁奖，当代艺术评论在窘境中重生？',
    url: 'http://www.tanchinese.com/feature/11517/',
  },
  {
    abstract:
      '那位喜欢在创作中搅动不同文化命签的“占卜者”黄永砅又出现了。2015年9月，他带着主要从罗马国立二十一世纪艺术博物馆MAXXI的国际巡回展而来的作品来到了北京红砖美术馆，以此作为该巡回展的第二站，主题为“蛇杖II”。',
    date: 'September, 2015',
    imgClass: 'huangYongping',
    publication: 'The Art Newspaper China',
    title: '“占卜者” 归来：黄永砅以 “蛇杖II”再起“反建制”争执',
    url: 'http://www.tanchinese.com/exhibitions/8635/',
  },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Writing: React.FC<WritingProps> = ({ dispatch }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)',
  });
  return (
    <div className="row">
      <div className={`${isDesktopOrLaptop ? 'col-2' : 'col-1'}`}></div>
      <div className={`${isDesktopOrLaptop ? 'col-8 px-5' : 'col-10 px-0'}`}>
        <div className={`d-flex flex-row flex-wrap justify-content-${isDesktopOrLaptop ? 'between' : 'center'}`}>
          {writingsArray.map((writing, index) => (
            <WritingEntry
              key={`writingEntry${index}`}
              abstract={writing.abstract}
              date={writing.date}
              imgClass={writing.imgClass}
              publication={writing.publication}
              title={writing.title}
              url={writing.url}
            />
          ))}
        </div>
      </div>
      <div className={`${isDesktopOrLaptop ? 'col-2' : 'col-1'}`}></div>
    </div>
  );
};
