import { About, Art, Contact, Log, LoginRHF, Profile, RegisterRHF, Writing } from '@cloverxuesongzhou/lib';
import { AppActionTypes, AppActions, AuthActions, ProjectsActions } from '@cloverxuesongzhou/types';
import React, { useContext } from 'react';

import { AppStateContext } from '../context/app/AppState';
import { AuthStateContext } from '../context/auth/AuthState';
import { GOOGLE_CLIENT_ID } from '../settings';
import { LightThroughTheCracks } from '../static/LightThroughTheCracks/LightThroughTheCracks';
import { logs } from '../static/Logs/Logs';
import { loginUser, registerUser } from '../utils/authUtils';

interface StateRouterProps {
  dispatch: (arg0: AuthActions | AppActions | ProjectsActions) => void;
}

export const StateRouter: React.FC<StateRouterProps> = ({ dispatch }) => {
  const { page } = useContext(AppStateContext);
  // const appDispatch = useContext(AppDispatchContext);
  const { authLoading, authError, user, checkedAuth } = useContext(AuthStateContext);

  switch (page) {
    case 'login': {
      return (
        <LoginRHF
          dispatch={dispatch}
          loginUser={loginUser}
          googleClientId={GOOGLE_CLIENT_ID}
          authLoading={authLoading}
          authError={authError}
          user={user}
          checkedAuth={checkedAuth}
        />
      );
    }

    case 'register': {
      return (
        <RegisterRHF
          dispatch={dispatch}
          registerUser={registerUser}
          googleClientId={GOOGLE_CLIENT_ID}
          authLoading={authLoading}
          authError={authError}
          user={user}
          checkedAuth={checkedAuth}
        />
      );
    }
    case 'profile': {
      return <Profile dispatch={dispatch} />;
    }
    case 'writing': {
      return <Writing dispatch={dispatch} />;
    }
    case 'art': {
      return <Art dispatch={dispatch} />;
    }
    case 'log': {
      return <Log dispatch={dispatch} logs={logs} />;
    }
    case 'about': {
      return <About dispatch={dispatch} />;
    }
    case 'contact': {
      return <Contact dispatch={dispatch} />;
    }
    case 'light-through-the-cracks': {
      return <LightThroughTheCracks />;
    }
    default: {
      // shouldn't be possible, but just in case.
      dispatch({ type: AppActionTypes.changePage, payload: 'writing' });
      return <Writing dispatch={dispatch} />;
    }
  }
};
