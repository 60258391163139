import React from 'react';

import magnolia from './img/Magnolia-resize.jpg';

export const logs = [
  {
    title: 'Magnolia',
    date: '08.08.20',
    text: (
      <p>
        From spring to summer, when so many flowers blossom, as I walk around the neighborhood, I always look around for
        flowers whenever I smell something sweet. For a long time that a secret, rich, and sweet fragrance had caught my
        attention, but whenever I searched for that particular fragrance, I could not find its source---until one day I
        found a magnolia flower than had fallen to the ground. I smelled it, and I realized that it had been magnolia
        the whole time!
        <br />
        <br />I first knew the English word &quot;magnolia&quot; from the 1999 film <em>Magnolia</em>. It is a
        psychological drama film that can evoke some complex sensations and feelings. However, the magnolia tree in
        southern California usually gives me the opposite impression: it is large and stable, upright and strong. I
        Googled the reason this film is called magnolia. Someone says that the film director, Paul Thomas Anderson, had
        this title even before he wrote the script. As he was doing research on the magnolia tree, he discovered that
        eating the tree&rsquo;s bark can help cure cancer. And yes, amid all the chaos and messes of different
        characters&rsquo; lives&mdash;childhood trauma, the pick-up artist lifestyle, drug addiction, cancer,
        etc.&mdash;there is a subtle theme of healing and love, and it is hidden there until almost the very end of the
        film.
        <br />
        <br />
        Therefore, I cannot help thinking about magnolias as a metaphor for God&rsquo; love. It is everywhere, yet
        unnoticeable. Only once you get to know it and its special fragrance do you become aware of its presence. Yet,
        it is not at the same level with us. It is higher, stronger&mdash;yet seemingly ordinary. It does not belong to
        or yield to the world, since you cannot pick a magnolia flower as you pick other flowers in the yard to put into
        a vase. People who are aware of its presence can only look up at it and admire it.
        <br />
        <br />
        Now I recall one of my favorite artworks of all time: Adrian Piper&rsquo;s{' '}
        <em>The Probable Trust Registry: The Rules of the Game #1-3</em>. In the &ldquo;game,&rdquo; the artist puts up
        three promises on the wall: &ldquo;I will always do what I say I am going to do&rdquo;; &ldquo;I will always be
        too expensive to buy&rdquo;; &ldquo;I will always mean what I say.&rdquo; In front of the promises stand three
        receptionists, each in a circular front desk, waiting to serve people to sign a contract with themselves about
        these promises. This work was first exhibited at the 56th <em>Venice Biennale</em>, which I was lucky enough to
        see in person as a journalist during the press preview day. Amid so many visually astonishing works in the{' '}
        <em>Biennale</em>, this work could be very easily ignored. It is too much like a normal reception spot. At first
        glance, I thought it was just the organizer providing some service, and I was about to pass by until I noticed
        the wise declarations on the wall. I was really glad that I didn&rsquo;t miss it, and I signed two of the
        promise-contracts. <em>La Venice Biennale</em> has always been an ocean of art to me. Amid the tons of arts that
        I saw, at the end of the day, Piper&rsquo;s work is the only one that gave me a profound impression. The next
        day, I was thrilled to know that this work won the Golden Lion Award of the 56th <em>Venice Biennale</em>.<br />
        <br />
        Since then, &ldquo;I will always be too expensive to buy&rdquo; is the promise that I recall from time to time.
        Whenever I feel I cannot help but bend to the world, I remember Jesus and Adrian Piper. Jesus has ransomed me
        from the world by His blood. I definitely should be too expensive to buy. Adrian Piper provided me an
        opportunity to make myself this promise in response to Christ&#39;s salvation. Even though I am too weak to keep
        this promise, as long as I would like to reach out to Jesus, He gives me the power.
        <br />
        <br />
        Do not yield to the world but to love and being loved.
      </p>
    ),
    imgURL: magnolia,
    imgAlt: 'a magnolia tree',
    width: '50',
    float: 'left',
  },
];
