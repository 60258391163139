import React, { useState } from 'react';

import { ArtItemMobile } from './ArtItemMobile';
import infinity0 from './img/infinity-series/0.png';
import infinity1 from './img/infinity-series/1.png';
import infinity2 from './img/infinity-series/2.png';
import infinity3 from './img/infinity-series/3.png';
import infinity4 from './img/infinity-series/4.png';
import infinity5 from './img/infinity-series/5.png';
import infinity6 from './img/infinity-series/6.png';
import infinity7 from './img/infinity-series/7.png';
import infinity8 from './img/infinity-series/8.png';
import infinity9 from './img/infinity-series/9.png';

const numberSeries = Array.from(Array(10).keys());

export const InfinitySeriesMobile: React.FC = () => {
  const [selected, setSelected] = useState('0');
  const [img, setImg] = useState(infinity0);

  const paintByNumber = (number: number): string => {
    switch (number) {
      case 0:
        return infinity0;
      case 1:
        return infinity1;
      case 2:
        return infinity2;
      case 3:
        return infinity3;
      case 4:
        return infinity4;
      case 5:
        return infinity5;
      case 6:
        return infinity6;
      case 7:
        return infinity7;
      case 8:
        return infinity8;
      case 9:
        return infinity9;
      default:
        return infinity0;
    }
  };

  return (
    <div className="artpage">
      <div className="d-flex flex-row justify-content-center mb-3">
        {numberSeries.map(
          (number): JSX.Element => {
            console.log(parseInt(selected) === number);
            return (
              <div
                key={`number${number}`}
                className={`${parseInt(selected) === number && 'artFrame'}`}
                style={{ width: '10%' }}
              >
                <img
                  style={{ width: '100%' }}
                  src={paintByNumber(number)}
                  alt={`Infinity Series - ${number}`}
                  onMouseEnter={(): void => {
                    setImg(paintByNumber(number));
                    setSelected('' + number);
                  }}
                />
              </div>
            );
          },
        )}
      </div>
      <ArtItemMobile date="2020" description="Acrilic on Canvas" img={img} title={`Infinity Series - ${selected}`} />
      <div className="text-justify">
        <h2 className="text-center">Artist&apos;s Statement</h2>
        <p>
          <i>Infinity Series</i> presents one set of serialized variations on the infinity symbol. The traditional
          infinity symbol can be conceptualized as a strip with a single 360 degree twist in it and then joined together
          at the “edges.” In the final shape, these conceptual “edges” disappear, hence the infinite quality of the
          loop. While experimenting with creating paper infinity loops, I noticed that adding additional rotations does
          not affect the shape in a determinate or regular way. Producing two-dimensional shapes from these loops
          involves creative decision-making. Meanwhile, because the resulting two-dimensional figures at some points
          inevitably rotate 180 degrees in one direction and then back again in the opposite direction, reading the net
          number of rotations in the representations of the loops requires careful attention.{' '}
        </p>

        <p>
          Thus, these figures strike a balance between more abstract and more literal number systems. Each symbol in{' '}
          <i>Infinity Series </i>could take an infinite number of forms—but not any and <i>all</i> forms: the range of
          possible forms for these infinity figures is constrained by the number of 180 degree rotations in the loop. In
          this sense, the figures are literal representations of the numbers which they represent. On the other hand,
          because these rotations can be difficult to discern even with the knowledge that it is the rotations which
          determine the numerical value of the figure, the “Infinity Numbers” pose an etymological mystery, similar to
          the opaque Brahmi numerals which underlie the popular Arabic numeral system. Thus, to review,{' '}
          <i>Infinity Series</i> presents a certain set (0-9) of an infinite series (0-∞) of serialy constrained but
          infinitely pluriform infinity loops.{' '}
        </p>

        <p>
          The series also plays on the paradox of the apparent loop in the spectrum of perceivable color. Color wheels
          “loop,” as it were, at the breakpoints of perception, and the “edges” of infrared and ultraviolet wind through
          an invisible meeting place which we can only represent with magenta. Like the numbers of{' '}
          <i>Infinity Series</i>, magenta is both determined by and opaque to the infinities (in multiple dimensions)
          which it, therefore, somewhat <i>is</i> and somewhat represents. Infinity series alludes to this mind-blasting
          absurdity by rotating through the color wheel at roughly 40 degree intervals. The foregrounds and backgrounds
          are offset by roughly 150 degrees. Note, of course, that it is their relative positions, as it were, within
          this infinite loop which render them distinguishable.{' '}
        </p>

        <p>
          Regarding the interpretation of the final visual form of the limited and thus sundry infinities of{' '}
          <i>Infinite Series, </i>extrapolate the above descriptions of numbers, forms, and colors to God and the nature
          of reality. Meanwhile, the art-making process also presents occasion for some interesting hermeneutical
          reflections. To change the form of one infinity loop into another, the loop has to be broken and rejoined
          (c.f. <i>religio</i>). It is part of the paradoxical nature of time that newness flows infinitely and
          instantaneously from the oldness. Perhaps it is all a projection of the redemptory truth of the real,
          “spiritual” cause of Creation: “The old has passed away; behold, the new has come.” (2 Corinthians 5:17b)
        </p>
        <div className="text-center text-secondary my-5">Copyright © 2020 Clover Xuesong Zhou</div>
      </div>
    </div>
  );
};
