import React from 'react';

interface ArtItemProps {
  date: string;
  description: string;
  img: string;
  title: string;
}

export const ArtItem: React.FC<ArtItemProps> = ({ date, description, img, title }) => {
  return (
    <div className="row mb-2 mt-5">
      <div className="col-3"></div>
      <div className="col-6 text-center">
        <img src={img} alt="" style={{ maxWidth: '100%' }} className="shadow" />
      </div>
      <div className="col-3 pt-5 pl-5">
        <div className="d-flex flex-column justify-content-end h-75">
          <div className="font-italic">{title}</div>
          <div>{description}</div>
          <div>{date}</div>
        </div>
      </div>
    </div>
  );
};
