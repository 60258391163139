import { AppActions, AuthActions } from '@cloverxuesongzhou/types';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { ArtMenu } from './ArtMenu';
import { ArtMenuMobile } from './ArtMenuMobile';
import { InfinitySeries } from './InfinitySeries';
import { InfinitySeriesMobile } from './InfinitySeriesMobile';
import { StopEmptiness } from './StopEmptiness';
import { StopEmptinessMobile } from './StopEmptinessMobile';

interface ArtProps {
  dispatch: (arg0: AuthActions | AppActions) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Art: React.FC<ArtProps> = ({ dispatch }) => {
  const [activeSeries, setActiveSeries] = useState('infinity');

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)',
  });

  return (
    <>
      {isDesktopOrLaptop ? (
        <div className="row">
          <div className="col-2 pt-5">
            <ArtMenu activeSeries={activeSeries} setActiveSeries={setActiveSeries} />
          </div>
          <div className="col-8 px-5">
            {activeSeries === 'infinity' && <InfinitySeries />}
            {activeSeries === 'stop' && <StopEmptiness />}
          </div>
          <div className="col-2"></div>
        </div>
      ) : (
        <div className="row">
          <div className="col-1"></div>
          <div className="col-10">
            <ArtMenuMobile activeSeries={activeSeries} setActiveSeries={setActiveSeries} />
            {activeSeries === 'infinity' && <InfinitySeriesMobile />}
            {activeSeries === 'stop' && <StopEmptinessMobile />}
          </div>
          <div className="col-1"></div>
        </div>
      )}
    </>
  );
};
