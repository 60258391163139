import '../WritingEntries.css';

import React, { useState } from 'react';

interface WritingEntryProps {
  abstract: string;
  date: string;
  imgClass: string;
  publication: string;
  title: string;
  url: string;
}

export const WritingEntry: React.FC<WritingEntryProps> = ({ abstract, date, imgClass, publication, title, url }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      style={{
        width: '17rem',
      }}
      onMouseEnter={(): void => setHovered(true)}
      onMouseLeave={(): void => setHovered(false)}
      className="mt-5"
    >
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'none' }}
        className={'text-dark'}
      >
        <div className={'writingEntry ' + imgClass}>
          {hovered && (
            <div className="d-flex flex-column justify-content-between h-100 font-caption">
              <div>
                <div
                  style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    color: 'rgb(210, 210, 210)',
                    display: 'inline',
                    borderRadius: '0rem 0rem 1rem 0rem',
                    padding: '.2rem .7em .2rem .2rem',
                  }}
                  className="font-writing-title"
                >
                  {date}
                </div>
              </div>

              <div
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                  color: 'rgb(210, 210, 210)',
                  display: 'block',
                  padding: '.2rem .7em .2rem .2rem',
                }}
                className="font-writing-title"
              >
                {abstract}
              </div>
            </div>
          )}
        </div>
        <div className="text-center mt-2 font-writing-title" style={{ lineHeight: '1.2rem' }}>
          {title}
        </div>
        <div className="text-center mt-1 font-writing-publication">{publication}</div>
      </a>
    </div>
  );
};
