import React from 'react';

import compositeLeviathan from './A-Composite-Leviathan.jpg';
import { LogItem } from './CustomLogItem-light-through';
import lightThroughImg from './Light-Through-The-Cracks.jpg';
import nabuqi from './Nabuqi--A-View-Beyond-Space--No-5.jpg';

const lightThroughTheCracksData = {
  title: (
    <>
      Light Through The Cracks: <br /> A Composite Leviathan
    </>
  ),
  date: '',
  text: (
    <>
      <p>Author: Clover Xuesong Zhou &amp; John Camden</p>
      <p>
        [This is a commissioned essay for the exhibition catalog <i>A Composite Leviathan</i>, published by Bridge
        Projects in Los Angeles published in 2020.]
      </p>
      <p>
        In 2008, the Hammer Museum sent curator and artist James Elaine to Beijing to scout for emerging Chinese
        contemporary artists. Living and working in China since then, Elaine has witnessed and contributed to the
        development of a new generation of Chinese artists. His subsequent work led him to establish Telescope, a
        nonprofit gallery in Beijing which jumpstarts the careers of aspiring contemporary artists by hosting and
        curating their first solo exhibitions. In 2019, Luhring Augustine gallery in New York invited Elaine to curate a
        Chinese contemporary art show. The exhibition, <i>A Composite Leviathan</i>, featured many of the emerging
        artists he had been working with since relocating to China. A few more artists have joined the exhibition in the
        process of its migration to Bridge Projects in Los Angeles.
      </p>

      <p>
        The term “leviathan” is attested to in the Biblical books of Job, Psalms, Isaiah, and Amos, referring to a type
        of sea dragon. It appears to be a facet of broader Semitic mythology, apparently representative of chaotic
        forces expelled by the creative order of deity. Thomas Hobbes’s 1651 political-philosophical treatise,{' '}
        <i>Leviathan</i>, further popularized the term, likely on account of the Westminster Assembly’s etymological
        determination that Biblical Hebrew לִוְיָתָן (<i>livyātān</i>) derives from the Semitic root לוה (<i>lwh</i>,
        “join”): “...because by his bigness he seems not one single creature, but a coupling of divers [<i>sic</i>]
        together; or because his scales are closed, or straitly compacted together.” According to Hobbes, in order to
        avert the “nasty, brutish, and short” outcomes of human nature, human beings yield their individual rights to an
        aggregated, “common coercive power.”
      </p>
      <div
        className="pl-5 pt-4 text-center"
        style={{ float: 'right', width: '50%', fontSize: '.8rem', color: '#383838' }}
      >
        <img
          style={{ width: '100%' }}
          src={compositeLeviathan}
          alt="'Light Through the Cracks,' published in A Composite Leviathan"
        />
        <div>
          Yang Jian, <i>A Composite Leviathan</i>, 2018. <br />
          lead board, rebar, iron wire, cement, polyurethane foam; <br />
          150 x 350 cm; 59 x 138 in
        </div>
      </div>

      <p>
        Accordingly, Yang Jian’s <i>A Composite Leviathan</i> consists of a hollow steel frame sporadically decked with
        lead plates bearing impressions from government-commissioned civic sculptures of mostly Chinese mythological
        creatures. Through gaping holes, one plainly sees the haphazard methods used in cobbling together the facade.
        The sculpture deftly presents China’s chaotic political and economic development. These sudden and drastic
        changes have left many empty or incomplete spaces waiting expectantly to be covered or merged.{' '}
      </p>

      <p>
        Despite the apparent sociopolitical foreground of its titular sculpture, the exhibition focuses mostly on
        connecting material issues to transcendant phenomena. In his essay “Self-Design and Aesthetic Responsibility,”
        Boris Groys observes that society inauthentically or even deceptively aestheticizes away its own ugliness—from
        cosmetic makeup to political propaganda. He entreats modern art to respond remedially through the pursuit of
        sincerity: “...the designed surface cracks open to offer a view of its inside.” Similarly, while preparing for
        the exhibition, a certain Leonard Cohen lyric came into Elaine’s mind: “There is a crack, a crack in everything.
        That’s how the light gets in.” Authenticity can be conceived of as a combination of cracks and light. Cracks are
        places of brokenness, but they also permit the ingress of light; accordingly, light reveals the cracks, but it
        also illuminates by means of those cracks. Combinations of cracks and light comprise both the diagnosis and the
        antidote to Groys’s problem of inauthentic aestheticization. While Groys’s aesthetic responsibility is mostly
        geared towards revealing the problems of “cracks” by shedding light on them, Cohen’s observation that cracks are
        “how the light gets in” recasts these cracks as the windows of opportunity for redemption. In short, the
        interpretive metaphor of “light and cracks” suggests the dual capacities of revelation and redemption.
      </p>

      <p>
        Some of the artworks in Bridge Projects’ <i>A Composite Leviathan</i> emphasize the revelatory aspect of
        shedding light on “cracks.” Li Ran’s <i>Persona Swap</i>, for instance, is a pseudo-documentary of Chinese
        theatrical makeup from 1958 to the early 1980s. The film primarily deals with the influence of nationalism in
        art and, conversely, the aestheticization of politics. Li highlights the phoniness of the makeup and exaggerated
        performances, subtly pointing to the transmogrifying phantasms of contemporary government narratives.
      </p>
      <p>
        Zhang Ruyi’s installation <i>Untitled Scenery</i> largely consists of a wall of white tiles—a common exterior
        building material in China. These are a perennial feature of Zhang’s artwork, presenting connotations of the
        uniform mass production of convenient sterility. Her use of an exterior building material for an interior wall
        subtly reverses notions of inside and outside. The implied room of <i>Untitled Scenery</i> features a functional
        aquarium with two plecostomus (or “suckermouth catfish”). A concrete sculpture of arid cacti cast in the role of
        an aquarium ornament satirizes the artificial reproduction of nature for consumer convenience—a common theme in
        Zhang’s artwork. Otherwise, the aquarium is minimalistic and bare. In this relative visual vacuum, one’s
        ever-consuming eye quickly resorts to the heater, filter, and light used to simulate an inhabitable environment.
        Glancing down at the environment-regulating air-conditioning unit which serves as the aquarium stand, one is
        suddenly aware of one’s own aquarium-like condition, and the white tile wall’s reversal attains its fulfillment.{' '}
      </p>

      <p>
        Jiu Jiu’s <i>The Year 2026 </i>and <i>Go Better</i> present apocalyptic scenes in two strikingly different
        videos. <i>Go Better </i>uses mirrored images of high-resolution content to present an accelerated microcosm of
        the modern life of luxury and all of its accoutrements. <i>The Year 2026 </i>is a collage of video clips from
        Fritz Lang’s ground-breaking science fiction blockbuster, <i>Metropolis</i> (1927). The clips are all negative
        inversions of the original black and white film, sped up and played in reverse. Jiu’s videos cause the viewer to
        reflect on human excesses, ambitions, and strife as particularly contemporary yet timeless issues.
      </p>

      <p>
        Other works in the exhibition emphasize the redemptory aspect of the cracks’ capacity for light. Christina
        Sharpe’s notion of “beauty as method” is especially relevant to those works. Sharpe, an African American scholar
        and writer, reflects on the competing forces of the racism she experienced during her childhood versus her
        mother’s unflagging resolve to beautify their household in the face of chaos. She describes the pursuit of
        beauty, quoting African American literary scholar Saidiya Hartman, as “a radical act of subsistence.” This
        perspective presents a counterbalance to the mostly European and European American rejection of aesthetics and
        the harsh exposure of Groys’s responsible aesthetics which sheds light <i>on</i> the cracks. Once authenticity
        has been restored, Sharpe’s beauty-as-method points toward the rehabilitation of aesthetics as a source of light
        for the redemption of brokenness.{' '}
      </p>

      <p>
        Zhao Yang’s painting <i>Roma Is a Lake </i>depicts a suburban lake near Beijing which once attracted a large
        community of artists. In violation of the artists’ 20-year leases, in June of 2019, the local government
        forcibly evicted the community in an agreement with real estate developers. First exhibited in March 2019,
        ominous overtones in the painting foreshadowed and thus illuminated corruption’s encroachment on the Roma
        community. However, the otherwise idyllic portrayal preserves and protects a bright, albeit now-nostalgic,
        memory of daily life around the lake in defiance of the miserable events which followed.
      </p>

      <p>
        Stephen Gleadow has lived in China for 12 years. His work focuses on the artistic and spiritual value of the
        trash and rubble left by the widespread demolition of studios and art zones. Remains of nylon banners and
        varieties of paper remind him of the “dust” from which all things came and to which all things return. He
        arranges the materials intuitively, angling towards a primitive state predating the encroachment of civilized
        rationality on one’s ecstatic union with the Divine.{' '}
      </p>

      <p>
        Some works in the exhibition reflect the egalitarian nature of light which shines on all things without
        discrimination; these works can be seen as a facet of the redemptory aspect of the cracks’ capacity for light.
        Zeng Hong uses realistic, classical painting strategies to portray abstract objects, such as Chinese characters
        or brush strokes. In <i>The People</i>, Zeng paints the Chinese characters for “the people” as cheap,
        factory-produced, metal objects. The style of the characters reflects the shabby but familiar, twentieth-century
        communist aesthetics of the massive, socialized factory/city in which Zeng used to reside. Zeng turns the
        spotlight to the common experience of the working class as a worthy object of aesthetic consideration. In other
        paintings, such as the <i>United Lines</i> series and <i>Divided Lines,</i> he focuses on the rudimentary
        elements of painting such as a single, colorful stroke. Shifting focus away from the sum of the parts, he
        portrays the building blocks as independent objects which have their own existence, valorizing these unsung
        heroes.
      </p>

      <p>
        Similarly, Liu Dongxu’s sculptures abstract ordinary objects from their utilitarian context in order to present
        them aesthetically. These transpositions are a subtle allegory for extricating human beings from our de facto,
        scientific-commercial habitat, inspiring us instead towards beautiful, existential independence.
      </p>

      <p>
        Many of the artworks in this exhibition simultaneously emphasize both the revelatory and the redemptive aspects
        of “light in the cracks.” Xie Hongdong’s photograph <i>Shore</i> serves as Bridge Projects’s headlining image.
        The photograph presents the fractured surface of an anonymous frozen lake. Broken pieces angling down into the
        water reflect brilliant sunlight amid a smattering of carpentry scraps and trash. The radiant beauty of the ice
        contrasts strikingly with the otherwise fractured and grubby scene. As such, the photograph presents elements of
        both Groys’s aesthetic responsibility and Sharpe’s beauty as method. Although exceedingly ephemeral and within a
        broken context, the shards of ice reflect glorious beams of light which bring hope.{' '}
      </p>
      <div
        className="pr-5 pt-4 text-center"
        style={{ float: 'left', width: '40%', fontSize: '.8rem', color: '#383838' }}
      >
        <img style={{ width: '100%' }} src={nabuqi} alt="'A View Beyond Space No. 5' by Nabuqi" />
        <div>
          Nabuqi, <i> View Beyond Space No. 5,</i> 2017 <br />
          bronze; 800 x 210 x 45 cm; 315 x 82 ½ x 17 ¾ in <br />
        </div>
      </div>

      <p>
        Nabuqi’s sculptures are inspired by urbanization, specifically by the overexpansion of the so-called “third
        line” cities of the Chinese city-tier system. In China, there are approximately 50 rapidly developed “cities of
        the future” built to stimulate the economy. Some wealthy people have exercised a partial faith in this vision,
        purchasing properties in these future cities without inhabiting them. Vacant and lifeless as these cities
        remain, it is not immediately obvious when and how they will prosper. These ghost cities are remarkable
        monuments of hope and regret, wonder and fear, imagination and loneliness. Nabuqi illuminates these “cracks” in
        Chinese society by capturing their mixed emotional milieus and rendering them in reverent or even sacred-feeling
        forms.{' '}
      </p>

      <p>
        Ye Su’s <i>Sharp Philtrum</i> is a ligneous open hexahedron laminated with prints of botanical photographs. Ye
        photographed the same collection of plants over the course of a single day, roughly every four hours, in order
        to capture the responses of the plants as living beings to the changes in light—from daylight to streetlight.
        The plants are a common Chinese topiary variety. However, rapid urbanization has supplanted, so to speak, much
        actual flora with printed murals of botanical photographs pasted on public walls. Ye mimics this method but
        subtly points to the insufficiency of the lifeless photographic substitutions by capturing some of the realtime
        adaptations of living plants. In the process, Ye not only presents a creative advance for urban Chinese
        botanical photographs but also encourages renewed appreciation for the irreplicability of the living subject
        behind the lens.{' '}
      </p>

      <p>
        Zhang Xinjun’s <i>Green Hole</i> is a tunnel constructed of tarps and ropes—common materials around the
        construction sites and temporary markets of rural migrant workers and peddlers in China. The tunnel is meant to
        convey a hole which has been eaten away by a worm. Zhang scales this hole up to human size to encourage the
        viewer to see things from the worm’s perspective. Simultaneously, the materials invite the viewer to stand in
        the shoes of China’s lowest classes whose lives are often neglected or, worse, despised as equivalent in value
        to that of worms. Through a shift in perspective, Zhang compassionately illuminates these aestheticized cracks
        in society.
      </p>

      <p>
        Some of the artists in the exhibition are more interested in highlighting cognitive, transcendent, or eternal
        phenomena. Fan Xi’s <i>Trees</i>, a series of photographic collages of trees, is a chief example. Much like
        Yang’s <i>A Composite Leviathan</i>, Fan compiles trees and branches into an unrealistic yet somehow organic
        whole. While Yang’s leviathan is a monument of Chinese social structures, Fan’s trees deal with far less
        concrete issues regarding the nature of memory. Each piece of her collages presents a different angle or
        impression of a tree or trees while the synthesis of these pieces into a single tree recalls, so to speak, the
        believable but exceedingly fallible reconstitutions of sense impressions in human memory. In this way, Fan sheds
        light on the often taken-for-granted nature of memory and, indeed, the cracks therein.
      </p>

      <p>
        Lyu Zhiqiang‘s installation <i>A Long Way</i> is an autobiographical work which presents his life as an artist.
        The architectural features of the installation reflect Lyu’s childhood memory of a waiting room in an old train
        station in the 1980s. The accompanying soundwork, also titled <i>A Long Way</i>, mostly consists of ordinary
        sounds that one might hear while traveling, whether by train or bus or with the aid of wapitis (such as elk or
        reindeer), etc. Behind a curtain, a video showcases a nine-hour trip that the artist took from Jiagedaqi in
        Inner Mongolia to Mohe, his hometown. Here, traveling can be interpreted as a metaphor for personal experience,
        transcendence, human history, or sociological changes. While travelling, one is inevitably removed from one’s
        normal routines and thereby enters into a different experience of time and space. Lyu’s delicate audio editing
        and his arrangement of the space transports his audience to that transcendent mode.
      </p>

      <p>
        Similar approaches can be seen in the works of Deng Tai, He Wei, Liu Fujie, Li Zhenwei, Wu Di, and Zhang Miao.
        Their material decisions and visual effects are less oriented around external issues, highlighting instead the
        features of the materials and images themselves. Deng’s ghostly photographic self-portraits convey a sense of
        intrusion of an other-worldly being in the mundane, urban realm. Hei’s abstract painting hinges on the competing
        and balancing forces of construction and destruction. Liu uses fragile materials to create her sculptures which
        seem to persist against the odds, like unstable chemistry compounds. Meanwhile, Li’s paintings consist of paint
        drops extruded by hand from oil paint tubes in patterns which refer to certain mathematical constants such as{' '}
        <i>pi </i>(π). Artistically plotting the unending decimal forms of irrational numbers connects Li to something
        transcendent. Wu’s videos and paintings steer the audience away from inherited cultural notions about gold to
        focus on the material itself. Similarly, Zhang’s paintings and installations challenge common associations with
        certain materials, reshaping them into strange spaces and objects which blur the lines of memory and
        unfamiliarity.
      </p>

      <p>
        Thus, the exhibition is indeed “A Composite Leviathan.” The metaphor of “light in the cracks” provides an
        interpretive framework to unite its sundry constituents. Some of the artists emphasize revelation, illuminating
        and thus revealing the inauthentic aestheticizing <i>away</i> of brokenness in the world. Others emphasize
        redemption by presenting the authentic truth <i>itself</i> as a worthy object of aesthetic consideration. These
        are two sides of the same coin, a currency of healing and love.
      </p>
      <hr />
      <p>
        Clover Xuesong Zhou is an art critic, art theologian, and visual artist. She is a longtime writer for ArtForum
        China. She has published hundreds of articles in Chinese art journals such as ArtForum China, The Art Newspaper
        China, The Art World, Randian, and Vision. She has a master of arts in fine art from Dalian Polytechnic
        University in China and a master of arts in theology from Fuller Theological Seminary.{' '}
      </p>

      <p>
        John Camden is a Biblical scholar, computer programmer, author, musician, and digital artist. John has studied,
        worshipped, and served alongside a wide range of Christian denominations, Orthodox, Catholic, and Protestant. He
        identifies with the “global ecumenical church,” and is currently pursuing a PhD in Old Testament theology at
        Fuller Theological Seminary.
      </p>
    </>
  ),
  imgURL: lightThroughImg,
  imgAlt: 'A Composite Leviathan by Yang Jian',
  width: '58',
  float: 'left',
};

const lightThroughTheCracksComponent = (
  <LogItem
    title={lightThroughTheCracksData.title}
    date={lightThroughTheCracksData.date}
    text={lightThroughTheCracksData.text}
    imgURL={lightThroughTheCracksData.imgURL}
    imgAlt={lightThroughTheCracksData.imgAlt}
    width={lightThroughTheCracksData.width}
    float={lightThroughTheCracksData.float}
  />
);

export const LightThroughTheCracks: React.FC = () => (
  <div className="row">
    <div className="col-2"></div>
    <div className="col-8 px-5">{lightThroughTheCracksComponent}</div>
    <div className="col-2"></div>
  </div>
);
