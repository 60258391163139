import React, { useState } from 'react';

interface ColorChunkProps {
  text: string;
}

export const ColorChunk: React.FC<ColorChunkProps> = ({ text }) => {
  const [color, setColor] = useState('#000000');
  const [secondColor, setSecondColor] = useState('#ffffff');
  // const [textShadowSize, setTextShadowSize] = useState('.01');

  const randomColor = (): string => Math.floor(Math.random() * 16777215).toString(16);

  return (
    <span
      onMouseEnter={(): void => {
        setColor(`#${randomColor()}`);
        setSecondColor(`#${randomColor()}`);
      }}
      onMouseLeave={(): void => {
        setColor(`#000000`);
        // setTimeout(() => {
        //   setSecondColor('#ffffff');
        // }, Math.floor(Math.random() * (5000 - 3000)) + 3000);
        // setSecondColor(`#${randomColor()}`);
      }}
      style={{ color: color, cursor: 'none', textShadow: `0px 0px 2px ${secondColor}` }}
      // className={`${color !== '#000000' && 'font-weight-bold'}`}
    >
      {text}
    </span>
  );
};
